<template>
    <modal ref="modalSelecAvatar" titulo="Selecciona un avatar" tamano="modal-lg" no-adicional no-cancelar :show-linea="false">
        <div class="row mx-0 mt-5 px-4 j-center">
            <img v-for="data in avatares" :key="data.id" :src="data.imagen" class="obj-cover rounded-circle border mr-3 mb-3 cr-pointer" width="65" height="65" @click="seleccionar(data)" />
        </div>
    </modal>
</template>

<script>
import Service from '~/services/auth'

export default {
    data(){
        return {
            avatares: [],
        }
    },
    methods: {
        async getAvatares(){
            try {
                const {data} = await Service.getAvatares()
                this.avatares = data.avatares;
            } catch(e){
                this.error_catch(e)
            }
        },

        seleccionar(data){
            this.$emit('changeAvatar', data);
            this.$refs.modalSelecAvatar.toggle();
        },

        toggle(){
            this.getAvatares();
            this.$refs.modalSelecAvatar.toggle();
        }
    }
}
</script>